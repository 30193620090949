<template>
  <v-container>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Boleta Electrónica</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row class="mt-4">
          <v-col cols="12" md="12">
            <v-card class="mx-auto">
              <v-card-subtitle>
                <v-row class="">
                  <v-col cols="12" md="1">
                    <v-btn class="ma-2" outlined x-large fab color="primary">
                      <v-icon color="primary">{{
                        "mdi-ticket-confirmation"
                      }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="11">
                    <p class="mt-8">
                      Pensado para negocios que requieran de una forma sencilla,
                      cómoda y eficaz para emitir boletas electrónica.
                    </p>
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </v-card>
            <br />
            <v-card class="mx-auto">
              <v-card-subtitle>
                <v-row class="">
                  <v-col cols="12" md="1">
                    <v-btn class="ma-2" outlined x-large fab color="primary">
                      <v-icon color="primary">{{
                        "mdi-desktop-classic"
                      }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="11">
                    <p class="mt-8">
                      Nuestra solución funciona mediante una aplicación para
                      Android, un POS dedicado, o su propio computador conectado
                      a una impresora térmica.
                    </p>
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" md="6"> </v-col>
        </v-row>
        <v-card flat class="mt-10" max-width="100%">
          <v-card class="mx-auto">
            <v-toolbar color="cyan darken-1" dark>
              <v-toolbar-title> Beneficios </v-toolbar-title>
            </v-toolbar>
            <v-list-item v-for="(benefit, i) in benefits" :key="i">
              <v-list-item-icon>
                <v-icon color="primary">{{ benefit.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ benefit.title }} </v-list-item-title>
                <v-list-item-subtitle>{{
                  benefit.subtitle
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card flat class="mt-10" max-width="100%">
            <v-card class="mx-auto">
              <v-toolbar color="cyan darken-1" dark>
                <v-toolbar-title>
                  Todos nuestros planes permiten
                </v-toolbar-title>
              </v-toolbar>
              <v-row class="mt-4 mb-5 ml-5">
                <v-col cols="12" md="8">
                  <v-list class="mt-8">
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="(plan, i) in descriptions_plans"
                        :key="i"
                      >
                        <v-list-item-icon>
                          <v-icon color="primary">{{ plan.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="plan.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                <v-col cols="12" md="4">
                  <v-img
                    src="../../../src/assets/pay2-pic11.png"
                    height="400"
                    width="400"
                    contain
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-card>
        <div class="mt-10 mb-10">
          <p class="text-h2 font-weight-bold text-center">
            Planes con compra de equipos.
          </p>
          <p class="text-center">
            Contrata aquí un plan base y compleméntalo con el equipo que más se
            acomode a tus necesidades.<br />
            Para ver equipos click
            <router-link to="/equipos"> aquí </router-link>.<br />
            Al adquirir tu propio equipo no pagarás arriendos, garantías ni
            mantenimientos.
          </p>
        </div>
        <v-row>
          
          <v-col
            cols="12"
            v-for="(plan, i) in plans"
            :key="i"
            md="4"
            class="pt-10"
          >
        
            <v-card
              v-if="plan.price > 0"
              elevation="10"
              height="100%"
              max-width="95%"
            >
              <v-card-title
                v-if="(i + 3)%3 == 0 && plan.price > 0"
                class="justify-center"
                style="background-color: #0e73b1"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-card-title
                v-if="(i + 2)%3 == 0 && plan.price > 0"
                class="justify-center"
                style="background-color: #e9501c"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-card-title
                v-if="(i + 1)%3 == 0 && plan.price > 0"
                class="justify-center"
                style="background-color: #179db0"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <br />
                <p
                  v-if="(i + 3)%3 == 0 && plan.price > 0"
                  class="text-center text-h3 font-weight-black"
                  style="color: #0e73b1"
                >
                  {{ plan.price }} CLP
                </p>
                <p
                  v-if="(i +2) %3 == 0 && plan.price > 0"
                  class="text-center text-h3 font-weight-black"
                  style="color: #e9501c"
                >
                  {{ plan.price }} CLP
                </p>
                <p
                  v-if="(i +1)%3== 0 && plan.price > 0"
                  class="text-center text-h3 font-weight-black"
                  style="color: #179db0"
                >
                  {{ plan.price }} CLP
                </p>
                <p class="text-center" v-if="plan.price > 0">
                  <strong> IVA incluido</strong>
                </p>
              </v-card-text>
              <v-divider v-if="plan.price > 0"></v-divider>
              <v-card-subtitle v-if="plan.price > 0">
                <div v-if="((i + 3)%3 == 0)">
                  <p>
                    <v-icon color="#0e73b1">mdi-check-circle</v-icon
                    >{{ plan.description }}
                  </p>
                </div>
                <div v-if="((i + 2)%3 == 0)">
                  <p>
                    <v-icon color="#e9501c">mdi-check-circle</v-icon
                    >{{ plan.description }}
                  </p>
                </div>
                <div v-if="(i + 1)%3 == 0">
                  <p>
                    <v-icon color="#179db0">mdi-check-circle</v-icon
                    >{{ plan.description }}
                  </p>
                </div>
              </v-card-subtitle>
              <v-divider v-if="plan.price > 0"></v-divider>
              <v-card-actions class="justify-center pt-3 pb-3">
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="(i + 3)%3 == 0 && plan.price > 0"
                  class="white--text mb-0 pb-0 mt-0"
                  color="#0e73b1"
                >
                  <v-icon right dark> mdi-cart </v-icon> Contratar
                </v-btn>
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="(i + 2)%3 == 0 && plan.price > 0"
                  class="white--text mb-0 pb-0"
                  color="#e9501c"
                >
                  <v-icon right dark> mdi-cart </v-icon> Contratar
                </v-btn>
                <v-btn
                  @click="contratar(plan.id)"
                  v-if="(i + 1)%3 == 0 && plan.price > 0"
                  class="white--text mb-0 pb-0"
                  color="#179db0"
                >
                  <v-icon right dark> mdi-cart </v-icon> Contratar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="2"></v-col>
        </v-row>
        <div class="mt-15 mb-15">
          <p class="text-h2 font-weight-bold text-center">Planes de arriendo</p>
        </div>
        <v-row>
          <v-col
            cols="12"
            v-for="(plan, i) in rental_plans"
            :key="i"
            md="3"
            class="pt-10"
          >
            <v-card
              v-if="plan.price_uf > 0"
              elevation="5"
              height="100%"
              max-width="100%"
            >
              <v-card-title
                v-if="i == 0 && plan.price == 0"
                class="justify-center"
                style="background-color: #0e73b1"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-card-title
                v-if="i == 1 && plan.price == 0"
                class="justify-center"
                style="background-color: #e9501c"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-card-title
                v-if="i == 2 && plan.price == 0"
                class="justify-center"
                style="background-color: #0e73b1"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-card-title
                v-if="i == 3 && plan.price == 0"
                class="justify-center"
                style="background-color: #e9501c"
              >
                <div class="justify-center white--text">{{ plan.name }}</div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <br />
                <p
                  v-if="i == 0 && plan.price == 0"
                  class="text-center text-h2 font-weight-black"
                  style="color: #0e73b1"
                >
                  {{ plan.price_uf }} UF
                </p>
                <p
                  v-if="i == 1 && plan.price == 0"
                  class="text-center text-h2 font-weight-black"
                  style="color: #e9501c"
                >
                  {{ plan.price_uf }} UF
                </p>
                <p
                  v-if="i == 2 && plan.price == 0"
                  class="text-center text-h2 font-weight-black"
                  style="color: #0e73b1"
                >
                  {{ plan.price_uf }} UF
                </p>
                <p
                  v-if="i == 3 && plan.price == 0"
                  class="text-center text-h2 font-weight-black"
                  style="color: #e9501c"
                >
                  {{ plan.price_uf }} UF
                </p>
              </v-card-text>
              <v-divider v-if="plan.price == 0"></v-divider>
              <div>
                <v-card-subtitle v-if="plan.price == 0">
                  <div v-if="i == 0">
                    <p
                      v-for="(item, index) in get_descriptions(
                        plan.description
                      )"
                      :key="index"
                    >
                      <v-icon style="color: #0e73b1">mdi-check-circle</v-icon>
                      {{ item + "." }}
                    </p>
                  </div>
                  <div v-if="i == 1">
                    <p
                      v-for="(item, index) in get_descriptions(
                        plan.description
                      )"
                      :key="index"
                    >
                      <v-icon style="color: #e9501c">mdi-check-circle</v-icon>
                      {{ item + "." }}
                    </p>
                  </div>
                  <div v-if="i == 2">
                    <p
                      v-for="(item, index) in get_descriptions(
                        plan.description
                      )"
                      :key="index"
                    >
                      <v-icon style="color: #0e73b1">mdi-check-circle</v-icon>
                      {{ item + "." }}
                    </p>
                  </div>
                  <div v-if="i == 3">
                    <p
                      v-for="(item, index) in get_descriptions(
                        plan.description
                      )"
                      :key="index"
                    >
                      <v-icon style="color: #e9501c">mdi-check-circle</v-icon>
                      {{ item + "." }}
                    </p>
                  </div>
                </v-card-subtitle>
              </div>
              <br />
              <br />
              <div
                class="ook"
                style="
                  bottom: 0;
                  position: absolute;
                  width: -webkit-fill-available;
                "
              >
                <v-divider v-if="plan.price == 0"></v-divider>
                <v-card-actions class="justify-center pt-3 pb-3">
                  <v-btn
                    @click="contratar(plan.id)"
                    v-if="i == 0 && plan.price == 0"
                    class="white--text"
                    color="#0e73b1"
                  >
                    <v-icon right dark> mdi-cart </v-icon> Adquirir
                  </v-btn>
                  <v-btn
                    @click="contratar(plan.id)"
                    v-if="i == 1 && plan.price == 0"
                    class="white--text"
                    color="#e9501c"
                  >
                    <v-icon right dark> mdi-cart </v-icon> Adquirir
                  </v-btn>
                  <v-btn
                    @click="contratar(plan.id)"
                    v-if="i == 2 && plan.price == 0"
                    class="white--text mb-2"
                    color="#0e73b1"
                  >
                    <v-icon right dark> mdi-cart </v-icon> Adquirir
                  </v-btn>
                  <v-btn
                    @click="contratar(plan.id)"
                    v-if="i == 3 && plan.price == 0"
                    class="white--text mb-2"
                    color="#e9501c"
                  >
                    <v-icon right dark> mdi-cart </v-icon> Adquirir
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <p class="mt-10 mb-10 font-weight-bold">
          *Planes de arriendo están sujetos al pago de una garantía que variará
          según el plan escogido, esta será devuelta íntegramente al cliente
          transcurrido un año a contar de la activación del servicio, o podrá
          utilizarla como parte de pago para adquirir el equipo arrendado.
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import Config from "../../config";

export default {
  data: () => ({
    plans: [],
    benefits: [
      {
        icon: "mdi-check-decagram",
        color: "primary",
        title:
          "Ya no tendrás que traspasar tus registros al libro de compra y venta.",
        subtitle:
          "Tus boletas automáticamente son ingresadas en el registro de ventas del SII.",
      },
      {
        icon: "mdi-check-decagram",
        color: "#2196F3",
        title: "Toda tu información contable en línea.",
        subtitle:
          "Tendrás acceso a una completa plataforma de facturación en donde revisar todos tus movimientos mensuales.",
      },
      {
        icon: "mdi-check-decagram",
        color: "primary",
        title: "Versatilidad de documentos.",
        subtitle:
          "Además de boletas, podrás emitir facturas, notas de crédito, guías de despacho y más sin costo adicional.",
      },
      {
        icon: "mdi-check-decagram",
        color: "primary",
        title: "Nunca más una boleta perdida.",
        subtitle:
          "Todas las boletas electrónicas que emitas quedarán automáticamente registradas, por lo que estarán todas siempre disponibles para ti.",
      },
      {
        icon: "mdi-check-decagram",
        color: "primary",
        title: "Mejora la experiencia de compra de tus clientes.",
        subtitle:
          "Es tan rápido y fácil de usar que tus clientes no tendrán que esperar a que realices complicados pasos para poder entregarle su boleta.",
      },
    ],
    rental_plans: [],
    descriptions: [],
    descriptions_plans: [
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Emisión ilimitada de documentos.",
      },
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Sincronización automática con el SII.",
      },
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Múltiples usuarios.",
      },
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Cuenta exclusiva para contadores.",
      },
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Emisión de documentos sin conexión internet*",
      },
    ],
  }),
  computed: {},
  mounted() {
    this.getPlans();
    this.getRentalPlans();
  },
  watch: {},
  methods: {
    get_descriptions(description) {
      let text = description;
      let result = text.split(".");
      return result;
    },
    contratar(id_plan) {
      this.$router.push({
        name: "create-contract-by-customer",
        params: {
          id_plan: id_plan,
          id_servicio: Config.idBoletaElectronica,
        },
      });
    },
    async getPlans() {
      let request = {
        id_service: Config.idBoletaElectronica,
      };
      axios
        .post("/plan-by-service", request)
        .then((response) => {
          console.log(response.data);
          this.plans = response.data;
          console.log("Planes");
          console.log(this.plans);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener planes");
        });
    },
    async getRentalPlans() {
      let request = {
        id_service: Config.idBoletaElectronica,
      };
      axios
        .post("show_rental_plan_by_service", request)
        .then((response) => {
          console.log(response.data);
          this.rental_plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener planes contratados"
          );
        });
    },
  },
};
</script>